<template>
  <div style="background:none">
    <v-expansion-panels v-model="activeSceneIndexes" focusable multiple>
      <v-expansion-panel
          v-for="(scene) in servicePatterns"
          :key="scene.id"
      >
        <v-expansion-panel-header hide-actions>
          <template v-slot:default="{ open }">
            <div>
              <div class="subtitle-1">{{ scene.name }}</div>
              <v-chip-group column>
                <v-chip v-for="item in scene.todo"
                        :key="item.id"
                        v-if="item.condition"
                        small
                        outlined
                        filter
                        @click.stop=""
                >
                  {{ item.condition }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template>
            <v-list two-line dense>
              <v-list-item-group
                  v-model="doneIndexes"
                  active-class="lime lighten-5 rounded"
              >
                <v-list-item v-for="item in scene.todo"
                             :key="item.id"
                             class="px-0"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mx-2">
                      <v-checkbox @click.stop=""></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title class="d-flex">
                        {{ item.name }}
                        <v-spacer/>
                        <v-chip v-for="la in item.labels"
                                :key="la"
                                small
                                class="mr-1"
                                @click.stop=""
                        >{{la}}</v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.content }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div v-if="false">
              <v-sheet v-for="item in scene.todo"
                       :key="item.id"
              >
                <div v-if="item.condition">
                  <v-btn plain small>{{ item.condition }}</v-btn>
                </div>
                <div>{{ item.name }}</div>
                <div>{{ item.content }}</div>
                <div>
                  <v-chip v-for="label in item.labels"
                          :key="label"
                          small
                  >
                    {{ label }}
                  </v-chip>
                </div>
              </v-sheet>
            </div>
          </template>
          <template>
            <v-divider class="my-4"/>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "ServicePattern",
  data: () => ({
    activeSceneIndexes: [0],
    doneIndexes: [],
    servicePatterns: [
      {
        id: 'enter_inquiry',
        name: '进入问询首页',
        todo: [
          {
            id: 'eV1BaOjAGm',
            sceneId: 'enter_inquiry',
            condition: '',
            type: 'todo',
            name: '介绍整体流程',
            content: '以后您的健康就由我们来守护了~',
            level: 1,
            labels: ['介绍', '寒暄'],
            endOfScene: false
          },
          {
            id: 'fDwUXyrc3a',
            sceneId: 'enter_inquiry',
            condition: '等待人多',
            type: 'todo',
            name: '引导分流',
            content: '现在人有点多, 您可以先扫码测一下体质',
            level: 1,
            labels: ['引导'],
            action: 'showConsCode',
            endOfScene: false
          },
          {
            id: 'yiEl6Al3za',
            sceneId: 'enter_inquiry',
            condition: '引导结束',
            type: 'todo',
            name: '开始建档',
            content: '接下来给开始给您建立健康档案',
            level: 1,
            labels: [],
            endOfScene: true
          }
        ],
        chat: [
          {
            id: 'W4nKbFF6Gy',
            sceneId: 'enter_inquiry',
            condition: '等待人多',
            type: 'chat',
            name: '转其他专员',
            content: '请稍等, 我给您联系其他专员',
            level: 1,
            labels: ['引导'],
            endOfScene: false
          }
        ]
      },
      {
        id: 'use_symptom_selector',
        name: '初始症状问询',
        todo: [
          {
            id: 'yZptRmgE2s',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'todo',
            name: '询问躯体症状',
            content: '您现在整体感觉怎么样,有哪儿不舒服么?',
            level: 1,
            labels: ['问询'],
            endOfScene: false
          },
          {
            id: 'tuwdEzVyDP',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'todo',
            name: '询问生命活动类症状',
            content: '您吃饭睡觉,整体感觉怎么样?',
            level: 1,
            labels: ['问询'],
            action: 'showLifeActionSymptoms',
            endOfScene: false
          },
          {
            id: '2a4bsn-UA8',
            sceneId: 'use_symptom_selector',
            condition: '初始症状完成',
            type: 'todo',
            name: '选择主诉',
            content: '这些问题您觉得那个最严重,最想解决?',
            level: 1,
            labels: ['问询', '主诉'],
            action: 'selectChiefComplaint',
            endOfScene: true
          }
        ],
        chat: [
          {
            id: 'hsKSiUUneB',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问躯体症状',
            content: '您头面部有不舒服么?',
            level: 1,
            labels: ['问询'],
            action: 'showBodySymptoms',
            endOfScene: false
          },
          {
            id: 'NUlkFyeclV',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问躯体症状',
            content: '您颈胸腰背部有不舒服么?',
            level: 1,
            labels: ['问询'],
            action: 'showBodySymptoms',
            endOfScene: false
          },
          {
            id: 'ORf1Lg7YyQ',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问躯体症状',
            content: '您四肢关节有不舒服么?',
            level: 1,
            labels: ['问询'],
            action: 'showBodySymptoms',
            endOfScene: false
          },
          {
            id: '_Ufs8gf5yQ',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问生命活动类症状',
            content: '您睡眠好么,有失眠多梦,入睡难容易醒么?',
            level: 1,
            labels: ['问询'],
            action: 'showLifeActionSymptoms',
            endOfScene: false
          },
          {
            id: 'cz1V3yeQjU',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问生命活动类症状',
            content: '您胃口怎么样?',
            level: 1,
            labels: ['问询'],
            action: 'showLifeActionSymptoms',
            endOfScene: false
          },
          {
            id: 'V7SPqLGU42',
            sceneId: 'use_symptom_selector',
            condition: '',
            type: 'chat',
            name: '询问生命活动类症状',
            content: '您大便怎么样,每天几次,成型么?',
            level: 1,
            labels: ['问询'],
            action: 'showLifeActionSymptoms',
            endOfScene: false
          }
        ]
      },
      {
        id: 'select_chief_complaint',
        name: '选择主诉问题',
        todo: [
          {
            id: 't39mmmRTYE',
            sceneId: 'select_chief_complaint',
            condition: '主诉选择完成',
            type: 'todo',
            name: '确定初始症状程度',
            content: '**程度, 比如**?',
            level: 1,
            labels: ['问询'],
            endOfScene: false
          },
          {
            id: 'sK2q76jpEP',
            sceneId: 'select_chief_complaint',
            condition: '初始症状程度确认',
            type: 'todo',
            name: '主症量表询问',
            content: '接下来详细给您记录一下**的情况?',
            level: 1,
            labels: ['过渡'],
            endOfScene: true
          }
        ],
        chat: []
      }
    ]
  })
}
</script>

<style scoped>
>>>.v-expansion-panel-content__wrap{
  padding: 0 8px;
}
</style>