<template>
  <div class="d-flex blue-grey">

    <v-overlay v-if="!urlValid">
      <v-progress-circular indeterminate/>
    </v-overlay>

    <!--    TODO 所有症状显示-->
    <symptom-selector v-show="status!=='diagnoseInquiry'&&status!=='recommendProduct'"
                      style="width: 540px"
                      class="flex-shrink-0"
                      :input-entities="entitiesFromConversations"
                      :negative-entity-ids="negativeEntityIds"
                      @update="getEntitiesFromSelector"
                      @selectMajor="getEntityScale($event)"
    />

    <!--  问询主体  -->
    <div :style="status==='recommendProduct'?'display:none!important;':''"
         class="flex-grow-1 h-100v overflow-y-auto rounded d-flex">
      <v-card v-if="entityScale.entityId"
              id="inquiry-panel"
              dark
              color="blue-grey"
              tile
              flat
              max-width="720"
              min-width="375"
              height="100%"
              :class="['pa-4 overflow-y-auto','mx-auto']"
              style="overflow: scroll"
      >
        <template v-if="status === 'entityInquiry'">
          <v-card-title class="pl-0 fixed-title blue-grey">
            主症询问
          </v-card-title>
          <div style="height: 80px"/>
          <qa-item v-for="qa in entityScale.questions"
                   dark
                   :input-value="entityResultBufferCopy[qa.questionId]"
                   :key="qa.questionId"
                   :color="invalids[qa.questionId] ? 'red lighten-2' : 'blue-grey darken-2'"
                   :question="{questionId: qa.questionId, text: qa.text}"
                   :answer="qa.answer"
                   @update="$set(entityResult, qa.questionId, $event)"
                   class="mb-6 border-box"
                   :style="forgets[qa.questionId]?'border: 2px solid #ffa2b2!important':''"
          />
        </template>

        <template v-if="status === 'returnVisit'">
          <v-card-title class="pl-0 fixed-title blue-grey">
            <v-btn icon @click="status='entityInquiry'" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            病症变化询问
          </v-card-title>
          <div style="height: 80px"/>
          <template v-if="historyEntities.diseases">
            <entity-severity-modifier v-for="(entity, i) in historyEntities.diseases"
                                      :key="entity.diseaseId"
                                      :input-entity="historyEntitiesCopy[entity.diseaseId]"
                                      @update="$set(historyEntities.diseases, i, $event)"
            />
          </template>
          <template v-if="historyEntities.symptoms">
            <entity-severity-modifier v-for="(entity, i) in historyEntities.symptoms"
                                      :key="entity.symptomId"
                                      :input-entity="historyEntitiesCopy[entity.symptomId]"
                                      @update="$set(historyEntities.symptoms, i, $event)"
            />
          </template>
        </template>

        <template v-if="status === 'symptomInquiry'">
          <v-card-title class="pl-0 fixed-title blue-grey">
            <v-btn icon @click="status=returnVisitMode||historyEntities.symptoms.length?'returnVisit':'entityInquiry'"
                   class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            整体询问
            <v-spacer/>
            <template v-for="item in severities">
              <span class="body-1">
                <v-icon :color="item.color">mdi-circle-medium</v-icon>
                {{ item.text }}
              </span>
            </template>
          </v-card-title>
          <div style="height: 80px"/>
          <div v-for="(groups, i) in predictRounds" :key="`g-${i}`">
            <symptom-item v-for="group in groups"
                          :key="group.groupId"
                          :group="group"
                          color="blue-grey darken-2"
            />
            <v-divider class="ma-6"/>
          </div>
        </template>

        <template v-if="status === 'pastHistoryInquiry'">
          <v-card-title class="pl-0 fixed-title blue-grey">
            <v-btn icon @click="status='symptomInquiry'" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            既往史
          </v-card-title>
          <div style="height: 80px"/>
          <qa-item v-for="(qa) in pastHistoryScale.questions"
                   :input-value="pastHistoryBufferCopy&&pastHistoryBufferCopy[qa.questionId]"
                   :key="qa.questionId"
                   :color="invalids[qa.questionId] ? 'red lighten-2' : 'blue-grey darken-2'"
                   :question="{questionId: qa.questionId, text: qa.text}"
                   :answer="qa.answer"
                   @update="$set(pastHistoryResult, qa.questionId, $event)"
                   class="mb-6 border-box"
                   :style="forgets[qa.questionId]?'border: 2px solid #ffa2b2!important':''"
          />
        </template>

        <template v-if="status === 'diagnoseInquiry'">
          <v-card-title class="pl-0 fixed-title blue-grey" style="width: 100%!important;">
            <v-btn icon @click="status='pastHistoryInquiry'" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            健康记录
          </v-card-title>
          <div style="height: 80px"/>
          <record :record="renderedRecord"
                  :input-diagnoses="diagnosesBufferCopy"
                  :diagnose-options="diagnoseOptions"
                  :health-suggestions="healthSuggestions"
                  @update="diagnoses=$event"
          />
        </template>

        <div class="w-100 mt-4 d-flex justify-space-between">
          <v-btn v-if="status!=='diagnoseInquiry'&&status!=='recommendProduct'"
                 :loading="loading"
                 color="blue-grey darken-4"
                 depressed
                 :style="{width: status==='symptomInquiry'?'66%':'100%'}"
                 @click="next"
          >
            <span v-if="status==='entityInquiry'">{{ returnVisitMode ? '病证变化更新' : '症状联想' }}</span>
            <span v-if="status==='returnVisit'">症状联想</span>
            <span v-if="status==='symptomInquiry'">下一轮</span>
            <span v-if="status==='pastHistoryInquiry'">生成报告</span>
          </v-btn>
          <template v-if="status==='diagnoseInquiry'">
            <v-btn :loading="loading"
                   depressed
                   :style="{width: status==='diagnoseInquiry'?'66%':'100%'}"
                   @click="updateDiagnoses"
            >
              保存诊断
            </v-btn>
            <v-divider class="mx-6" vertical/>
            <v-btn depressed
                   class="flex-grow-1"
                   outlined
                   @click="recommendProduct"
            >
              产品推荐
            </v-btn>
          </template>
          <template v-if="status==='symptomInquiry'">
            <v-divider class="mx-6" vertical/>
            <v-btn depressed
                   class="flex-grow-1"
                   outlined
                   @click="manualStopSymptomInquiry"
            >
              <v-icon :color="canFinish?'green':'error'" small left>mdi-{{ canFinish ? 'check' : 'alert' }}</v-icon>
              结束联想
            </v-btn>
          </template>
        </div>
      </v-card>
      <div v-else class="w-100p h-100p pa-8">
        <h1 class="pa-8 text-h5 blue-grey darken-2 white--text rounded">←请选择待问询的主症</h1>
      </div>
    </div>

    <!--  产品推荐  -->
    <template v-if="status==='recommendProduct'">
      <v-card-title class="fixed-title blue-grey white--text" style="width: 100%!important;">
        <v-btn dark icon @click="status='diagnoseInquiry'" class="mr-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        健康记录
      </v-card-title>
      <health-plan
          :record-id="$store.state.userInfo.recordId"
          :input-product-plan="inputProductPlan"
          margin-top="80px"
      />
    </template>

    <!--  百科提示-->
    <div v-if="status==='diagnoseInquiry'"
         class="fixed px-8 overflow-y-auto"
         style="left: 0; width: 480px; height: 100vh;"
    >
      <div style="height: 80px"/>
      <baike-panel :entities="baikeEntities"/>
    </div>

    <template v-if="userConversation">
      <v-btn
          fab
          small
          fixed
          top
          right
          style="z-index: 100"
          @click="showConversations=!showConversations"
      >
        <v-icon>mdi-{{ showConversations ? 'close' : 'microphone-settings' }}</v-icon>
      </v-btn>

      <conversation-assistant v-show="showConversations" @selectEntity="getEntityFromConversation"/>
    </template>

    <!--  量表弹窗  -->
    <template>
      <v-dialog
          v-model="showAssistantPanel"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              fab
              color="blue-grey lighten-2"
              dark
              small
              v-bind="attrs"
              v-on="on"
              style="position: fixed; bottom: 12px; right: 12px; z-index: 100"
          >
            <v-icon small>mdi-qrcode-scan</v-icon>
          </v-btn>
        </template>

        <v-card
            color="blue-grey lighten-4"
            class="d-flex justify-space-around pa-12">
          <v-card v-for="scale in assistantScales"
                  :key="scale.name"
                  color="blue-grey lighten-5"
                  class="text-center"
          >
            <v-card-title>{{ scale.name }}</v-card-title>
            <v-card-text>
              <vue-qr :text="scale.url"
                      :size="160"
                      :white-margin="false"
                      :margin="0"
                      class="mb-4"
              />
              <v-btn color="primary" small block class="mb-4" @click="go(scale.url)">
                本地打开
              </v-btn>
              <v-btn color="success" small block outlined @click="go(scale.result)">
                查看结果
              </v-btn>
            </v-card-text>
          </v-card>
        </v-card>

      </v-dialog>
    </template>

    <service-pattern class="service-pattern"/>
  </div>
</template>

<script>
import QaItem from "../components/QaItem";
import SymptomItem from "../components/SymptomIitem";
import {mapState} from "vuex";
import SymptomSelector from "../components/SymptomSelector";
import Record from "../components/Record";
import ConversationAssistant from "../components/ConversationAssistant";
import EntitySeverityModifier from "../components/EntitySeverityModifier";
import VueQr from 'vue-qr'
import BaikePanel from "../components/Baike/BaikePanel";
import HealthPlan from "../components/HealthPlan";
import ServicePattern from "../components/ServiceAssistant/ServicePattern";

export default {
  name: "Inquiry",
  components: {
    ServicePattern,
    HealthPlan,
    BaikePanel,
    VueQr, EntitySeverityModifier, ConversationAssistant, Record, SymptomSelector, SymptomItem, QaItem
  },
  props: {
    entityId: String
  },
  data: () => ({
    // entityId: '05b6a3d7621b9aa50070c2b8125cb587',
    urlValid: false,
    showAssistantPanel: false,
    userConversation: false,
    recordStartTime: new Date().valueOf(),
    defaultScale: false,
    entityScale: {},
    chiefComplaint: '',
    entityResult: {},
    forgets: {},
    invalids: {},
    status: 'entityInquiry',
    predictRounds: [],
    predictInfo: [],
    canFinish: false,
    stop: false,
    pastHistoryScale: {},
    pastHistoryResult: {},
    diagnoseOptions: [],
    entitiesFromSelectorData: [],
    entitiesFromSelector: {symptoms: [], disease: []},
    renderedRecord: {},
    diagnoses: {
      sure: [],
      suspected: []
    },
    healthSuggestions: [],
    showConversations: false,
    entitiesFromConversations: [],
    negativeEntityIds: [],
    bufferTrigger: -1,
    bufferKeys: ['recordStartTime', 'entityScale', 'chiefComplaint', 'defaultScale', 'entityResult', 'status', 'predictRounds', 'predictInfo', 'canFinish', 'stop',
      'pastHistoryScale', 'pastHistoryResult', 'diagnoseOptions', 'entitiesFromSelectorData', 'entitiesFromSelector',
      'renderedRecord', 'diagnoses', 'historyEntities', 'returnVisitMode'],
    bufferJsonString: null,
    entityResultBufferCopy: {},
    pastHistoryBufferCopy: {},
    diagnosesBufferCopy: {},
    lastRecordExists: false,
    lastRecordBuffer: null,
    historyEntitiesCopy: {},
    historyEntities: {symptoms: [], diseases: []},
    returnVisitMode: false,
    selectedProductIds: [],
    inputProductPlan: []
  }),
  computed: {
    ...mapState([
      "severities", "loading", "userInfo"
    ]),
    assistantScales() {
      const uid = this.userInfo?.customerId
      return [
        {
          name: '心理社会关系多维评测',
          url: `http://tcm.yunbian.link/custSclRecord/showSclIndex?custCode=${uid}`,
          result: `http://tcm.yunbian.link/custSclRecord/showSclIndex?custCode=${uid}`,
        },
        {
          name: '中医体质辨识',
          url: `http://tcmc.yunbian.link/physiqueQuestion/directIframeOther?custCode=${uid}`,
          result: `http://tcmc.yunbian.link/custPhysiqueRes/openPhysiqueReport?custCode=${uid}`
        },
        {
          name: '基础信息填写',
          url: `http://tcmc.yunbian.link/custHealthInfos/openHealthy?custCode=${uid}`,
          result: `http://tcmc.yunbian.link/custHealthInfos/openHealthy?custCode=${uid}`
        },
      ]
    },
    baikeEntities() {
      const symptoms = this.predictRounds.reduce((p, c) => {
        c.forEach(e => {
          p.push(...e.symptoms.filter(s => s.severity).map(e => e.name))
        })
        return p
      }, [])
          .concat(this.entitiesFromSelector.symptoms.filter(s => s.severity).map(e => e.name))
      const diseases = this.entitiesFromSelector.diseases.filter(s => s.severity).map(e => e.name)
      const syndromes = this.diagnoseOptions.reduce((p, c) => {
        p.push(...c.map(e => e.name.replace('证', '')))
        return p
      }, [])
      return {
        symptoms,
        diseases,
        syndromes
      }
    }
  },
  watch: {
    entityResult: {
      deep: true,
      handler(v) {
        // console.log({entityResult: v})
      }
    }
  },
  beforeCreate() {
    const shortUrl = this.$route.params.shortUrl
    this.$store.commit('updateState', {key: 'token', value: shortUrl})
    this.$store.dispatch('request', {
      action: 'checkUrl',
      params: {shortUrl, route: 'inquiry'},
      fn: () => {
        this.urlValid = true
        this.recordStartTime = new Date().valueOf()
        this.$store.dispatch('request', {
          action: 'recoverInquiry',
          params: {},
          triggerLoading: false,
          fn: async ({buffer, lastRecord}) => {
            if (lastRecord?.buffer) {
              console.log('导入历史: ', lastRecord?.buffer)
              this.lastRecordExists = true
              this.lastRecordBuffer = lastRecord.buffer
              this.historyEntitiesCopy = JSON.parse(JSON.stringify(
                  Object.fromEntries([...lastRecord.historyEntities.diseases, ...lastRecord.historyEntities.symptoms]
                      .map(e => [e.symptomId || e.diseaseId, e]))
              ))
              this.historyEntities = lastRecord.historyEntities
              let date = '上次'
              if (lastRecord.recordFinishTime) {
                date = new Date(lastRecord.recordFinishTime)
                date = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
              }
              try {
                await this.$confirm({title: '导入问询记录', text: `是否导入${date}问询记录?`})
                this.returnVisitMode = true
                // 借用对话变量将实体传入病症选择器
                this.entitiesFromConversations = this.lastRecordBuffer?.entitiesFromSelectorData || []
                Object.entries(this.lastRecordBuffer).forEach(([k, v]) => this[k] = v)
                this.$nextTick(() => {
                  this.entityResultBufferCopy = JSON.parse(JSON.stringify(this.lastRecordBuffer.entityResult))
                  this.pastHistoryBufferCopy = JSON.parse(JSON.stringify(this.lastRecordBuffer.pastHistoryResult))
                  this.entitiesFromConversations = []
                })
                return
              } catch (e) {
              }
            }
            if (buffer) {
              console.log('恢复缓存: ', buffer)
              this.historyEntitiesCopy = JSON.parse(JSON.stringify(
                  Object.fromEntries([...buffer.historyEntities.diseases, ...buffer.historyEntities.symptoms]
                      .map(e => [e.symptomId || e.diseaseId, e]))
              ))
              this.historyEntities = buffer.historyEntities
              // 借用对话变量将实体传入病症选择器
              this.entitiesFromConversations = buffer.entitiesFromSelectorData || []
              Object.entries(buffer).forEach(([k, v]) => this[k] = v)
              this.$nextTick(() => {
                this.entityResultBufferCopy = JSON.parse(JSON.stringify(buffer.entityResult))
                this.pastHistoryBufferCopy = JSON.parse(JSON.stringify(buffer.pastHistoryResult))
                this.diagnosesBufferCopy = JSON.parse(JSON.stringify(buffer.diagnoses))
                this.entitiesFromConversations = []
              })
            }
          }
        })
      },
      fail: () => {
        // alert('NotFound')
        this.$router.push({name: 'NotFound', params: {shortUrl: '404'}})
      }
    })
  },
  mounted() {
    if (this.entityId) this.getEntityScale(this.entityId)
    this.bufferTrigger = setInterval(() => {
      this.bufferInquiry()
    }, 15 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.bufferTrigger)
  },
  methods: {
    go(url) {
      window.open(url, '_blank')
    },
    bufferInquiry() {
      const buffer = Object.fromEntries(this.bufferKeys.map(e => [[e], this[e]]))
      const bufferJsonString = JSON.stringify(buffer)
      if (bufferJsonString === this.bufferJsonString) return
      this.bufferJsonString = bufferJsonString
      this.$store.dispatch('request', {
        action: 'bufferInquiry',
        params: {buffer},
        triggerLoading: false,
        fn: () => {
          console.log('问诊过程已缓存')
        }
      })
    },

    getEntitiesFromSelector(e) {
      this.entitiesFromSelectorData = e
      const symptoms = e.filter(e => e.category === 'symptom' || e.category !== 'disease')
          .map(e => {
            e.symptomId = e.id
            e.severity = e.severity || 1
            // delete e.id
            return e
          })
      const diseases = e.filter(e => e.category === 'disease')
          .map(e => ({
            diseaseId: e.id,
            name: e.name
          }))
      this.entitiesFromSelector = {symptoms, diseases}
    },

    getEntityFromConversation(e) {
      // console.log(e)
      this.entitiesFromConversations.push({...e, severity: 1, source: 'conversations'})
    },

    getEntityScale(entity) {
      this.chiefComplaint = entity.renderName || entity.name
      this.$store.dispatch('request', {
        action: 'getEntityScale',
        params: {
          entityId: entity.id
        },
        // triggerLoading: false,
        fn: data => {
          this.entityScale = data
          this.defaultScale = data.defaultScale
        },
        fail: () => {
          this.entityScale = {}
        }
      })
    },

    valid() {
      this.invalids = {}
      this.forgets = {}
      let valid = true
      for (let q of this.entityScale.questions) {
        const exists = this.entityResult[q.questionId]
        // console.log(exists, q.text, exists?.valid)
        if (!exists && q.answer.mandatory) {
          this.$set(this.forgets, q.questionId, true)
          valid = false
        }
        if (exists && !exists.valid) {
          this.$set(this.invalids, q.questionId, true)
          valid = false
        }
      }
      if (!valid) this.$pop('可能有遗漏或不合格回答,请仔细检查!', 'warning')
      return valid
    },

    manualStopSymptomInquiry() {
      if (this.canFinish || confirm('目前诊断方向未明确, 是否强行终止?')) {
        this.stop = true
        this.next()
      }
    },

    next() {
      if (this.status === 'diagnoseInquiry') {
        // this.$pop('本次')
      }
      /**
       * 清除缓存混乱引起的entityResult问题
       */
      this.entityResult = Object.fromEntries(Object.entries(this.entityResult)
          .filter(([_, q]) => this.entityScale.questions.find(e => e.questionId === q.questionId))
          .sort((a, b) => (this.entityScale.questions.findIndex(e => e.questionId === a[0])
              - this.entityScale.questions.findIndex(e => e.questionId === b[0]))))
      let params = {
        // testMode: true,
        chiefComplaint: this.chiefComplaint,
        entityResult: {
          entityId: this.entityId,
          chiefComplaint: this.chiefComplaint,
          defaultScale: this.defaultScale,
          answers: Object.values(this.entityResult)
        },
        entitiesFromSelector: this.entitiesFromSelector,
        historyEntities: this.historyEntities,
        predictInfo: this.predictInfo,
        symptomInfo: undefined,
        stop: this.stop,
        status: this.status,
        returnVisitMode: this.returnVisitMode,
        pastHistoryResult: {
          answers: Object.values(this.pastHistoryResult),
        },
        diagnose: undefined,
        recordStartTime: this.recordStartTime
      }
      let status = 'entityInquiry'
      // console.log(this.status, 'valid: ', this.valid())
      if (this.status === 'entityInquiry') {
        if (!this.valid()) {
          status = 'entityInquiry'
          return
        }
        if (this.returnVisitMode) {
          this.status = 'returnVisit'
          return
        }
        status = 'symptomInquiry'
      }
      // 根据病症变化, 删除病症选择器中实体
      if (this.status === 'returnVisit') {
        this.negativeEntityIds = this.historyEntities.diseases
            .filter(e => e.change === 'heal' || e.severity === 0)
            .map(e => e.diseaseId)
            .concat(this.historyEntities.symptoms
                .filter(e => e.change === 'heal' || e.severity === 0)
                .map(e => e.symptomId))
        // console.log(this.negativeEntityIds)
        status = 'symptomInquiry'
        this.stop = false
      }
      if (this.status !== 'returnVisit' && this.predictRounds.length) {
        const escapeGroupIds = this.predictRounds.reduce((p, c) => p.concat(c.filter(e => e['escape']).map(e => e.groupId)), [])
        const _mergeSymptoms = round => round.reduce((p, c) => p.concat(c.symptoms.map(e => ({
          symptomId: e.symptomId,
          text: e.text,
          severity: e.severity || 0
        }))), [])
        /** 计算阳性症状数量, 并更新predictInfo数组 */
        const positiveCount = _mergeSymptoms(this.predictRounds[this.predictRounds.length - 1])
            .filter(e => e.severity)
            .length
        this.predictInfo.push(positiveCount)
        const symptoms = this.predictRounds.reduce((p, c) => p.concat(_mergeSymptoms(c)), [])
        params.symptomInfo = {escapeGroupIds, symptoms}
        status = 'symptomInquiry'
        this.stop = false
      }
      // 因为上一步会污染this.stop,所以用params.stop代替
      if (this.status === 'symptomInquiry' && params.stop) status = 'pastHistoryInquiry'
      if (this.status === 'pastHistoryInquiry') status = 'diagnoseInquiry'
      params.status = status
      // return
      this.$store.dispatch('request', {
        action: 'processRequest',
        params,
        // triggerLoading: false,
        fn: (data) => {
          this.status = status
          if (data.recordStartTime) this.recordStartTime = data.recordStartTime
          if (status === 'symptomInquiry') {
            this.canFinish = data.canFinish
            this.predictRounds.push(JSON.parse(JSON.stringify(data.result)))
          }
          if (status === 'pastHistoryInquiry') {
            this.pastHistoryScale = data.pastHistoryScale
            this.pastHistoryResult = {}
          }
          if (status === 'diagnoseInquiry') {
            this.diagnoseOptions = data.diagnoseOptions
            this.renderedRecord = data.renderedRecord
          }
        }
      })
    },

    updateDiagnoses() {
      if (!this.diagnoses.sure.length && !this.diagnoses.suspected.length) {
        this.$pop('诊断结果不能为空!', 'warning')
        return
      }
      const params = {
        operatorId: this.userInfo.uid,
        recordId: this.renderedRecord.recordId,
        diagnoses: {
          sure: this.diagnoses.sure.map(e => ({...e, score: 3})),
          suspected: this.diagnoses.suspected.map(e => ({...e, score: 1.5}))
        }
      }
      this.$store.dispatch('request', {
        action: 'updateRecordDiagnoses',
        params,
        // triggerLoading: false,
        fn: data => {
          this.$pop('诊断保存成功!')
          console.log(data)
        }
      })
    },

    recommendProduct() {
      this.status = 'recommendProduct'
    },
  }
}
</script>

<style scoped>
.fixed-title {
  position: fixed;
  top: 0;
  width: calc(100% - 400px);
  height: 80px;
  z-index: 10;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

#inquiry-panel::-webkit-scrollbar {
  width: 4px !important;
}

.service-pattern {
  position: fixed;
  right: 12px;
  top: 12px;
  width: 375px;
  max-height: 85%;
  overflow-y: auto;
  z-index: 1000
}
</style>