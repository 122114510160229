<template>
  <div class="w-100v d-flex pa-8 overflow-x-auto">
    <v-card v-for="(s, i) in schedule.schedules"
            :key="i"
            elevation="8"
            class="mr-8"
    >
      <v-card-title>{{ s.date }} {{ s.day }}</v-card-title>
      <v-card-subtitle>距离现在: {{ fromNow(new Date(s.date)) }}</v-card-subtitle>

      <!-- 工作日志 -->
      <v-card flat shaped color="#fffbe6">
        <v-card-subtitle class="d-flex align-center">
          工作日志
          <v-spacer/>
          <v-btn icon small @click="workLogByDay[s.date].push({
            id: '',
            name: '',
            content: '',
            type: 'note',
            done: false,
            level: 0,
            date: s.date,
            alarmTimes: []
          })">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-subtitle>
        <v-card-text>
          <draggable id="sure-group"
                     v-model="workLogByDay[s.date]"
                     :group="{name: s.date}"
                     animation="300"
          >
            <workLog-item v-for="(workLog, i) in workLogByDay[s.date]"
                          :key="workLog.id"
                          v-model="workLogByDay[s.date][i]"
                          :index="i+1"
                          :schedules="s"
                          class="mb-1"
                          @updateRelated="updateWorkLogRelated"
                          @change="changeWorkLog"
            />
          </draggable>
        </v-card-text>
      </v-card>

      <!-- 任务列表 -->
      <template v-for="(timeRange, i) in s.schedule">
        <v-card-subtitle class="d-flex font-weight-bold" :style="{backgroundColor: i%2?'#f6f6f6':''}">
          {{ timeRange.time }}
          <v-spacer/>
          {{ timeRange.scheduleItems.length ? `待办事项: ${timeRange.scheduleItems.length}条` : '空闲' }}
        </v-card-subtitle>
        <v-card-text :style="{backgroundColor: i%2?'#f6f6f6':''}">
          <div v-for="item in timeRange.scheduleItems"
               :key="s.date+timeRange.time+item.id"
          >
            <div class="d-flex">
              <v-icon
                  v-if="workLogRelated.some(t => t.itemId === item.id && t.date === s.date && t.time === timeRange.time)"
                  small color="orange">
                mdi-chevron-down
              </v-icon>
              <template
                  v-for="rel in getRelatedWorkLogs(s.date, workLogRelated.filter(t => t.itemId === item.id && t.date === s.date && t.time === timeRange.time).map(e => e.workLogId))"
              >
                <div :key="s.date+timeRange.time+item.id+rel"
                     class="related"
                     @click="getWorkLogInfo(rel)"
                >
                  {{ rel.index }}
                </div>
              </template>
            </div>

            <product-card
                v-if="item.planCategory==='product'"
                :product="item"
                @changeSchedule="$emit('setProductSchedule',[$event, item])"
                @delete="removeItem('toggleProductSelect',item)"
                class="mb-4"
            />
            <medicine-card
                v-if="item.planCategory==='medicine'"
                :medicine="item"
                @changeSchedule="$emit('setMedicineSchedule', [$event, item])"
                @delete="removeItem('removeMedicine',item)"
                class="mb-4"
            />
            <diary-card
                v-if="item.planCategory==='diary'"
                :diary="item"
                @changeSchedule="$emit('setDiarySchedule',[$event, item])"
                @delete="removeItem('toggleDiarySelect', item)"
                class="mb-4"
            />
          </div>
          {{ timeRange.scheduleItems.length ? `` : '当前时段没有计划~' }}
        </v-card-text>
      </template>
    </v-card>

    <v-btn fab fixed bottom right color="success" :loading="$store.state.loading" @click="updateWorkLogs">
      <v-icon>mdi-content-save-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import ProductCard from "./HealthPlanCard/ProductCard";
import MedicineCard from "./HealthPlanCard/MedicineCard";
import DiaryCard from "./HealthPlanCard/DiaryCard";
import * as SillyDate from 'silly-datetime'
import WorkLogItem from "./WorkLogItem";
import draggable from 'vuedraggable'

SillyDate.locate('zh-cn')

export default {
  name: "WeekSchedule",
  components: {WorkLogItem, DiaryCard, MedicineCard, ProductCard, draggable},
  props: {
    recordId: String,
    schedule: Object
  },
  data: () => ({
    workLogs: [],
    workLogByDay: {},
    workLogRelated: [],
    // 只更新保存变动的workLog
    changedWorkLogs: {},
    renderSchedules: []
  }),
  watch: {
    /**
     * 性能优化尝试
     */
    // 'schedule.schedules': {
    //   deep: true,
    //   immediate: true,
    //   handler(v) {
    //     if (!v) return
    //     v.forEach(d => {
    //       const idx = this.renderSchedules.findIndex(r => r.date === d.date)
    //       if (idx < 0) this.renderSchedules.push(d)
    //       else {
    //         // const s = d.schedule
    //         // const _s = this.renderSchedules[idx].schedule
    //         if (JSON.stringify(d) !== JSON.stringify(this.renderSchedules[idx])) this.renderSchedules.splice(idx, 1, d)
    //       }
    //     })
    //   }
    // }
  },
  beforeMount() {
    this.workLogByDay = Object.fromEntries(this.schedule.schedules.map(s => ([s.date, []])))
    this.$emit('saveWorkLogs', this.workLogs)
    this.$store.dispatch('request', {
      action: 'getWorkLogs',
      params: {
        type: 'record',
        recordIds: [this.recordId],

      },
      fn: data => {
        this.workLogs = data
        this.parseWorkLogByDay()
      }
    })
  },
  methods: {
    fromNow: SillyDate.fromNow,
    parseWorkLogByDay() {
      this.workLogs.forEach(e => {
        const date = e.date
        const has = this.workLogByDay[date]?.find(w => w.id === e.id)
        if (!has) this.workLogByDay[date].push(e)
      })
      Object.keys(this.workLogByDay).forEach(d => {
        this.workLogByDay[d] = this.workLogByDay[d].sort((a, b) => a.index - b.index)
      })
    },
    updateWorkLogRelated(related) {
      const _other_workLogRelated = this.workLogRelated.filter(e => e.date !== related.date)
      // const _this_workLogRelated = this.workLogRelated.filter(e => e.date === related[0].date && e.time === related[0].time)
      this.workLogRelated = [...related.items, ..._other_workLogRelated]
    },
    changeWorkLog(workLog) {
      this.changedWorkLogs[workLog.id] = workLog
      this.workLogs = Object.values(this.workLogByDay).reduce((p, c) => p.concat(c), [])
    },
    getRelatedWorkLogs(date, workLogIds) {
      const workLogs = this.workLogByDay[date]
      return workLogIds.map(e => {
        const idx = workLogs.findIndex(t => t.id === e)
        return {
          id: workLogs[idx].id,
          name: workLogs[idx].name,
          index: idx + 1
        }
      })
          .sort((a, b) => a.index - b.index)
    },
    getWorkLogInfo(workLog) {
      // console.log(workLog)
      this.$pop({
        text: workLog.name,
        color: 'primary',
        top: true
      })
    },
    removeItem(emit, item) {
      const yes = confirm('是否确定从所有日程中删除该条目?')
      if (yes) this.$emit(emit, item)
    },
    parseHealthPlan() {
      const scheduleItems = this.schedule.schedules.reduce((p, c) => p.concat(
          c.schedule.reduce((_p, _c) => _p.concat(_c.scheduleItems.filter(e => !_p.find(i => i.id === e.id))), [])
              .filter(e => !p.find(i => i.id === e.id))
      ), [])
      const productPlan = scheduleItems
          .filter(e => e.planCategory === 'product')
          .reduce((p, c) => {
            const productCategory = c.productCategoryItems
            let products = p[productCategory]
            if (products) products.push(c)
            else p[productCategory] = [c]
            return p
          }, {})
      const medicinePlan = scheduleItems.filter(e => e.planCategory === 'medicine')
      const diaryPlan = scheduleItems.filter(e => e.planCategory === 'diary')
      return {
        productPlan: Object.values(productPlan),
        medicinePlan,
        diaryPlan
      }
    },
    // DONE 同时更新方案数据
    updateWorkLogs() {
      // console.log(Object.values(this.changedWorkLogs))
      // console.log(this.workLogs)
      // return
      const healthPlans = this.parseHealthPlan()
      this.$emit('saveHealthPlans', healthPlans)
      console.log(healthPlans)
      // return
      this.$store.dispatch('request', {
        action: 'saveHealthPlans',
        params: {
          recordId: this.recordId,
          ...healthPlans
        },
        // triggerLoading: false,
        fn: data => {
          localStorage.setItem(this.recordId, '""')
          if (!Object.keys(this.changedWorkLogs).length) return
          this.$emit('saveWorkLogs', this.workLogs)
          this.$store.dispatch('request', {
            action: 'saveWorkLogs',
            params: {
              workLogs: Object.values(this.changedWorkLogs).map(e => ({
                ...e,
                operatorId: this.$store.state.userInfo.userId,
                recordId: this.recordId
              }))
            },
            // triggerLoading: false,
            fn: () => {
              this.$pop('工作计划保存成功!')
              this.changedWorkLogs = {}
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>
.related {
  box-sizing: border-box;
  background-color: orange;
  border-radius: .2em;
  text-align: center;
  color: whitesmoke;
  line-height: 1.4em;
  padding: 0 .5em;
  margin-bottom: 2px;
  margin-right: 4px;
}
</style>